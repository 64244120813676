import React, { useState } from 'react';
import { Row } from 'reactstrap';
import Mirror from './components/mirror';
import Iframe from './components/iframe';
import AnimatedComponent from '../../components/animated-component';
import NavBar from '../../components/navbar';
import AppLayout from '../../layout/appLayout';

const Screen = () => {

  const [powerOn, setPowerOn] = useState(false);
  const [paddingValue, setPaddingValue] = useState(15);
  const [urlIframe, setUrlIframe] = useState('https://www.gucci.com/');
  const [isAnimating, setIsAnimating] = useState(false);

  return(
    <AppLayout paddingValue={paddingValue} >
      <Row className='m-0 p-0 w-100 h-100' style={{background: 'black'}}>
        <NavBar 
          paddingValue={paddingValue} 
          setPaddingValue={setPaddingValue}
          powerOn={powerOn}
          setPowerOn={setPowerOn}
          isAnimating={isAnimating}
          setIsAnimating={setIsAnimating}
        />
        <AnimatedComponent
          isVisible={powerOn}
          delay={0.5}
          animation="fade">
          <Mirror /> 
        </AnimatedComponent> 
        <AnimatedComponent
          isVisible={!powerOn}
          delay={0.5}
          animation="fade">
          <Iframe 
          urlIframe={urlIframe}
          setUrlIframe={setUrlIframe}
          isAnimating={isAnimating}
          paddingValue={paddingValue}
          />
        </AnimatedComponent> 
      </Row>
    </AppLayout>

  );


}
export default Screen;