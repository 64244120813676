import React , {useState, useEffect} from 'react';
import { Row } from 'reactstrap';
import {ReactComponent as PowerSVG} from '../../assets/img/navbar/power.svg';
import { ReactComponent as PlusSVG } from '../../assets/img/navbar/plus.svg';
import { ReactComponent as MinorSVG } from '../../assets/img/navbar/minor.svg';
import './styles.scss';
import FillAnimation from '../../components/fill-animation';

const NavBar = ({powerOn,setPowerOn, paddingValue, setPaddingValue, isAnimating, setIsAnimating}) => {

  const [counter, setCounter] = useState(0);



  const power = () => {

    if(!isAnimating){
      setPowerOn(!powerOn);
      setIsAnimating(true);
    }
    setTimeout(() => {
      setIsAnimating(false);
    }, 2500);

  }


  const increasePadding = () => {

      if(counter < 3){
        setPaddingValue(paddingValue + 15);
        setCounter(counter + 1);
      }

    }

  const decreasePadding = () => {

    if(paddingValue > 15){
      setPaddingValue(paddingValue - 15);
      setCounter(counter - 1);
    }

  }


  useEffect(() => {

    const animation = document.getElementById("fill");

    if(powerOn){
      setTimeout(() => {
        animation.style.opacity = 0;
      }, 2000);
    }
    else{
      setPaddingValue(15);
      setCounter(0);
      setTimeout(() => {
        animation.style.opacity = 1;
      }, 2000);
    }

    const navbar = document.getElementById("navbar");

    if(isAnimating){
      navbar.style.pointerEvents = "none";
    }
    else{
      navbar.style.pointerEvents = "auto";
    }


  },[powerOn, isAnimating])


  return(

    <>
    <FillAnimation powerOn={powerOn}/>
    <Row style={{right : paddingValue - 15}} className="container">
        <Row className={powerOn ? "boxOn" : "boxOff"}>
          <div id="fill" className={powerOn ? "fill" : "empty"} />
          <div id="navbar" className={powerOn ? "navBarOn" : "navBarOff"}>
            <PlusSVG className={powerOn ? "plusSvgOn" : "plusSvgOff"} onClick={() => increasePadding()} />
            <PowerSVG className={powerOn ? "powerSvgOn" : "powerSvgOff"} onClick={() => power()} />
            <MinorSVG className={powerOn ? "minorSvgOn" : "minorSvgOff"} onClick={() => decreasePadding()} />
          </div>
        </Row>
    </Row>
    </>

  );
  
}
export default NavBar;