import React, { useEffect, useState } from 'react';
import './styles.scss';

const AnimatedComponent = ({ children, isVisible, delay, animation }) => {
  const [renderComponent, setRenderComponent] = useState(false);

  const showComponent = () => {
    setTimeout(() => {
      setRenderComponent(true);
    }, delay * 1000);
  };

  const hideComponent = () => {
    setTimeout(() => {
      setRenderComponent(false);
    }, delay * 1000);
  };

  const getAnimationClass = () => {
    if(isVisible) return `${animation}In`;
    else return `${animation}Out`;
  };

  useEffect(() => {
    if(isVisible) showComponent();
    else hideComponent();
  }, [isVisible]);

  if(!renderComponent) return <></>;
  return (
    <div
      className='m-0 p-0 w-100 h-100'
      style={{
        animation: `${getAnimationClass()} ease-in-out ${delay}s`,
      }}>
      {children}
    </div>
  );
};

export default AnimatedComponent;