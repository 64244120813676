import React, {useEffect, useState} from 'react';
import './styles.scss';

const FillAnimation = ({powerOn}) => {

  const styles = {

    rightTop: {

      position: "absolute", 
      top: "0", 
      right: "0", 
      width: "15px", 
      height: "50%", 
      margin: "0", 
      padding: "0", 
      zIndex: "2", 
      transform: "scaleY(0)", 
      background: "linear-gradient(0deg,#00B1FF 0%,#00B1FF 80% ,#ffffff 100%)", 
      transformOrigin: "0 100%", 
      animation: "powerOnYRight 1s forwards",
      border: '0',

    },

    rightBottom: {

      position: "absolute", 
      bottom: "0", 
      right: "0", 
      width: "15px", 
      height: "50%", 
      margin: "0", 
      padding: "0", 
      zIndex: "2", 
      transform: "scaleY(0)", 
      background: "linear-gradient(0deg,#ffffff 0% ,#00B1FF 20%,#00B1FF 100%)", 
      transformOrigin: "100% 0", 
      animation: "powerOnYRight 1s forwards",
      border: '0',

    },

    top: {

      position: "absolute", 
      top: "0", 
      left: "0", 
      width: "100%", 
      height: "15px", 
      margin: "0", 
      padding: "0", 
      zIndex: "1", 
      transform: "scaleX(0)", 
      background: "linear-gradient(45deg,#ffffff 0% ,#00B1FF 20%,#00B1FF 100%)", 
      transformOrigin: "100% 0", 
      animation: "powerOnX 1s forwards 0.6s",
      border: '0',

    },

    bottom: {

      position: "absolute", 
      bottom: "0", 
      left: "0", 
      width: "100%", 
      height: "15px", 
      margin: "0", 
      padding: "0", 
      zIndex: "1", 
      transform: "scaleX(0)", 
      background: "linear-gradient(45deg,#ffffff 0% ,#00B1FF 20%,#00B1FF 100%)", 
      transformOrigin: "100% 0%", 
      animation: "powerOnX 1s forwards 0.6s",
      border: '0',

    },

    leftTop: {

      position: "absolute", 
      top: "0", 
      left: "0", 
      width: "15px", 
      height: "50%", 
      margin: "0", 
      padding: "0", 
      transform: "scaleY(0)", 
      background: "linear-gradient(0deg,#ffffff 0% ,#00B1FF 20%,#00B1FF 100%)", 
      transformOrigin: "100% 0", 
      animation: "powerOnYLeft 1s forwards 1.1s",
      border: '0',
      zIndex: "2", 

    },

    leftBottom: {

      position: "absolute", 
      bottom: "0", 
      left: "0", 
      width: "15px", 
      height: "50%", 
      margin: "0", 
      padding: "0", 
      transform: "scaleY(0)", 
      background: "linear-gradient(0deg,#00B1FF 0%,#00B1FF 80% ,#ffffff 100%)", 
      transformOrigin: "0 100%", 
      animation: "powerOnYLeft 1s forwards 1.1s",
      border: '0',
      zIndex: "2", 

    },

    left: {

      position: "absolute", 
      top: "0", 
      left: "0", 
      width: "15px", 
      height: "100%", 
      margin: "0", 
      padding: "0", 
      transition: "0.5s", 
      background: "#00B1FF", 
      opacity: "0", 
      animation: "powerLeftOpacity 0.5s forwards 1.5s",
      border: '0',
      zIndex: "2", 

    },
  }

  
  const [showAnimation, setShowAnimation] = useState(null);

  useEffect(() => {

    const animation = document.getElementById("animationPowerOn");
   
    if(powerOn){
      setShowAnimation(true);
      setTimeout(() => {
        animation.style.transition = "1.5s";
        animation.style.opacity = 0;
      }, 2000);
    }
    else{
      setShowAnimation(false);
      animation.style.opacity = 1;
    }

  },[powerOn])

  return(
        
        <div id="animationPowerOn">
          {
            showAnimation &&
              <>
                <div style={styles.rightTop} />
                <div style={styles.rightBottom}/>
                <div style={styles.top} />
                <div style={styles.bottom} />
                <div style={styles.leftTop}/>
                <div style={styles.leftBottom} />
                <div style={styles.left} />
            </>
          }
        </div>

  );

}

export default FillAnimation;