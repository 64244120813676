import React , {useState,useEffect, useReducer} from 'react';
import { Button, Row } from 'reactstrap';
import {ReactComponent as GucciSVG} from '../../../assets/img/brands/Gucci.svg';
import { ReactComponent as RalphSVG } from '../../../assets/img/brands/RalphLauren.svg';
import { ReactComponent as SacoorSVG } from '../../../assets/img/brands/Sacoor.svg';

import './styles.scss';

const Iframe = ({urlIframe,setUrlIframe, paddingValue}) => {  


  const brands = [{ name : "Gucci",
                    url : "https://www.gucci.com/", 
                    id : 1,
                  },
                  { name : "Ralph Lauren",
                    url : "https://www.ralphlauren.com/",
                    id : 2,
                  },
                  { name : "Sacoor",
                    url : "https://www.sacoorbrothers.com/",
                    id : 3,
                  }
                 ];

                           
  const styles = {

    brandsContainer : {
  
      position: "absolute",
      display: "flex",
      justifyContent:"center",
      width: `calc(100% - ${paddingValue * 2}px)`,
      transition: "1s",
      height: "fit-content",
      margin: "0",
      padding: "0",
      bottom: `calc(85px + ${paddingValue}px)`,
      opacity: "0",
      zIndex: "5",


      brands : {
        
        width: "fit-content",
        margin: "0",
        padding: "0",
        mixBlendMode: "difference",


      },
    },
  }

  const changeIframe = (url) => {

    if(url !== urlIframe ){
      const brandId = document.getElementById("iframe");
      brandId.style.opacity = 0;
      brandId.style.transition = "0.5s";
      setUrlIframe(url);
      setTimeout(() => {
      brandId.style.opacity = 1;
      }, 1500);
    }
    else{
      return null;
    }
  }


  return(
    <Row className='w-100 h-100 p-0 m-0 iframeContainer'>
       <iframe is="x-frame-bypass" id="iframe" title="website" className="p-0 m-0 w-100 h-100" src={urlIframe} sandbox="allow-scripts">
      </iframe>
      <Row id="brandsContainer" style={styles.brandsContainer}>
        <Row style={styles.brandsContainer.brands}>
          {
            brands.map((brand) => (
              <Button key={brand} id={brand?.id} value={brand?.url} className="button" onClick={() => changeIframe(brand?.url)}>
                {
                (
                brand?.name === "Gucci" && 
                  <GucciSVG 
                  style={brand?.url === urlIframe ? {fill : "black", transition: "0.5s"} : {fill : "#C2C2C2",transition: "0.5s"}}
                  />
                )
                ||
                (
                 brand?.name === "Ralph Lauren" && 
                  <RalphSVG className="ralphSvg" 
                  style={brand?.url === urlIframe ? {fill : "black", transition: "0.5s"} : {fill : "#C2C2C2",transition: "0.5s"}}
                  />
                )   
                || 
                (
                 brand?.name === "Sacoor" && 
                  <SacoorSVG 
                  style={brand?.url === urlIframe ? {fill : "black", transition: "0.5s"} : {fill : "#C2C2C2",transition: "0.5s"}}
                 />
                ) 
                }
              </Button>
            )
            )
          }
        </Row>
      </Row>
    </Row>
      
  );


}
export default Iframe;