import React from 'react';
import { Row } from 'reactstrap';

const Mirror = () => {

  

  const styles = {

    Mirror : {
      background : "black",
      margin: "0",
      padding: "0",
      width: "100%",
      height: "100%",
  
    },
    
  }

  return(
    
    <Row style={styles.Mirror}>
    </Row>  


  );
}


export default Mirror;